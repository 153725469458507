import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateTime } from 'luxon';
import { StoneService } from 'src/app/components/riva-gems/stone.service';
import { ReportService } from 'src/app/services/report.service';
import { StoneMovementsReport } from './stone-movements-report';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'stone-movements-report',
  templateUrl: './stone-movements-report.component.html',
  styleUrls: ['./stone-movements-report.component.scss'],
})
export class StoneMovementReportComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  stoneMovementReports = new MatTableDataSource<StoneMovementsReport>([]);
  displayedColumns = [
    'stonesId',
    'type',
    'cut',
    'clarity',
    'color',
    'caratsPerUnit',
    'size',
    'labGrown',
    'traceable',
    'amountPurchased',
    'amountReleased',
    'amountInStock',
  ];

  isExportingStoneInventory = false;
  isExportingStoneMovement = false;
  isShowAllData = true;
  filters: Filters = {
    dateRangeFrom: DateTime.local().minus({ months: 9 }).toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
  };

  constructor(
    private stoneService: StoneService,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.stoneMovementReports.sort = this.sort;
    this.stoneMovementReports.paginator = this.paginator;
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
  }

  onDownloadExcelFileForStoneInventory() {
    this.isExportingStoneInventory = true;
    this.stoneService.getStoneExcel().subscribe((response) => {
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'stone-inventory-report.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      this.isExportingStoneInventory = false;
    });
  }

  onDownloadExcelFileForStoneMovement() {
    this.isExportingStoneMovement = true;
    const params = this.isShowAllData
      ? null
      : {
          start: this.filters.dateRangeFrom,
          end: this.filters.dateRangeTo,
        };
    this.reportService.getStoneMovementReports(params).subscribe((response) => {
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'stone-movement-report.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      this.isExportingStoneMovement = false;
    });
  }
}
