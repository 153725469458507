import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InvoiceFairmined } from '../components/reporting/general-reports/invoice-reports/invoice-fairmined';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends GenericService {
  baseUrl = environment.apiUrl + 'report';
  reportsUrl = environment.apiUrl + 'reports';

  constructor(http: HttpClient) {
    super(http);
  }

  generateWorkOrder(orderId: number) {
    return this.http.get<any>(this.baseUrl + '/workorder-report/' + orderId, {
      headers: this.headers,
    });
  }

  generateProductInventory() {
    return this.http.get<any>(this.baseUrl + '/productinventory-report', {
      headers: this.headers,
    });
  }

  getInvoiceFairmined(start, end) {
    return this.http
      .get<InvoiceFairmined[]>(`${this.reportsUrl}/invoice-fairmined`, {
        headers: this.headers,
        params: {
          start,
          end,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            link: `/invoicing-and-shipping/invoicing/${item.invoicesId}`,
          })),
        ),
      );
  }

  getStoneMovementReports(params) {
    return this.http.get(
      this.reportsUrl + '/stone-movement',
      {
        headers: this.headers,
        responseType: 'arraybuffer',
        params
      },
    );
  }
}
