<div class="stone-movement-reports">
  <div class="button-container">
    <div class="report-item">
      <h1 style="color: white">Stone Inventory Report</h1>
      <div></div>
      <button
        class="btn btn-primary"
        [disabled]="isExportingStoneInventory"
        (click)="onDownloadExcelFileForStoneInventory()"
        style="width: 350px; text-transform: unset"
      >
        Download Excel Report
      </button>
    </div>
    <div class="report-item">
      <h1 style="color: white">Stone Movement Report</h1>
      <div style="width: 350px">
        <mat-slide-toggle
          [(ngModel)]="isShowAllData"
          style="color: white; margin-bottom: 16px"
          >Show all data</mat-slide-toggle
        >
        <fieldset
          [disabled]="isShowAllData"
          [ngStyle]="{ opacity: isShowAllData ? 0.4 : 1 }"
        >
          <riva-date-range-picker
            [startDate]="filters.dateRangeFrom"
            [endDate]="filters.dateRangeTo"
            (onChange)="onDateRangeChange($event)"
          ></riva-date-range-picker>
        </fieldset>
      </div>
      <button
        class="btn btn-primary"
        [disabled]="isExportingStoneMovement"
        (click)="onDownloadExcelFileForStoneMovement()"
        style="width: 350px; text-transform: unset"
      >
        Download Excel Report
      </button>
    </div>
    <!-- <div>
      
      <button
        class="btn riva-button"
        [disabled]="isExportingStoneMovement"
        (click)="onDownloadExcelFileForStoneMovement()"
      >
        <span>Stone Movement Report</span>
      </button>
    </div> -->
  </div>
</div>
