export class MaterialCode {
  materialCodeId: number = 0;
  code: string = '';
  karat: string = '';
  color: string = '';
  description: string = '';
  printColor1?: string = '';
  multiMetal?: boolean;
  multiMetalPrimary?: number;
  multiMetalPrimaryMaterial?: MaterialCode;
  multiMetalSecondary?: number;
  multiMetalSecondaryMaterial?: MaterialCode;
}

export class MatCodeSize {
  productID: number;
  matID: number;
  size: number;
  uom: number;
  qtyStock: number;
  wtgr: number;
  whlsprice: number;
  msrp: number;
}

export class Sizes {
  size: number;
}

export class SizeUpdate {
  materialCodeId: number;
  oldSize: number;
  newSize: number;
}

export interface MaterialCodeCalculation {
  materialCodesCalculationsID: number;
  materialCodeID: number;
  baseMetal1: number;
  preciousPercentage1: number;
  baseMetal2: number;
  preciousPercentage2: number;
  alloyCost: number;
  lossFactor: number;
  castingFee: number;
  bottomCutOff: number;
  topCutOff: number;
  totalGrams: number;
  totalCastingFee: number;
  totalItemCost: number;
}

export interface MaterialCalculation
  extends MaterialCode,
    MaterialCodeCalculation {}
