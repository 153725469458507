<div class="stone-dialog">
  <mat-dialog-content class="mat-typography">
    <div class="stone-variation-container">
      <div>
        <div class="image-container">
          <div *ngIf="!currentStonePicture">No Image</div>
          <img
            *ngIf="currentStonePicture"
            style="max-width: 100%; max-height: 200px"
            [src]="currentStonePicture"
          />
        </div>
      </div>
      <div>
        <mat-form-field
          appearance="fill"
          [ngClass]="getFieldBorder(stoneTypeControl)"
        >
          <mat-label>Type</mat-label>
          <input
            type="text"
            matInput
            [formControl]="stoneTypeControl"
            [matAutocomplete]="stoneType"
            (blur)="onTypeCheck()"
            (ngModelChange)="onGetImage()"
          />
          <mat-autocomplete
            #stoneType="matAutocomplete"
            [displayWith]="displayStoneType"
          >
            <mat-option
              *ngFor="let type of filteredTypes | async"
              [value]="type"
              style="width: 260px"
            >
              {{ type.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          [ngClass]="getFieldBorder(stoneCutControl)"
        >
          <mat-label>Cut</mat-label>
          <input
            type="text"
            matInput
            [formControl]="stoneCutControl"
            [matAutocomplete]="stoneCut"
            (blur)="onCutCheck()"
            (ngModelChange)="onGetImage()"
          />
          <mat-autocomplete
            #stoneCut="matAutocomplete"
            [displayWith]="displayStoneCut"
          >
            <mat-option
              *ngFor="let cut of filteredCuts | async"
              [value]="cut"
              style="width: 260px"
            >
              {{ cut.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          [ngClass]="getFieldBorder(stoneColorControl)"
        >
          <mat-label>Color</mat-label>
          <input
            type="text"
            matInput
            [formControl]="stoneColorControl"
            [matAutocomplete]="stoneColor"
            (blur)="onColorCheck()"
            (ngModelChange)="onGetImage()"
          />
          <mat-autocomplete
            #stoneColor="matAutocomplete"
            [displayWith]="displayStoneColor"
          >
            <mat-option
              *ngFor="let color of filteredColors | async"
              [value]="color"
              style="width: 260px"
            >
              {{ color.colorName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          [ngClass]="getFieldBorder(stoneClarityControl)"
        >
          <mat-label>Clarity</mat-label>
          <input
            type="text"
            matInput
            [formControl]="stoneClarityControl"
            [matAutocomplete]="stoneClarity"
            (blur)="onClarityCheck()"
          />
          <mat-autocomplete
            #stoneClarity="matAutocomplete"
            [displayWith]="displayStoneClarity"
          >
            <mat-option
              *ngFor="let clarity of filteredClarities | async"
              [value]="clarity"
              style="width: 260px"
            >
              {{ clarity.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <div class="size-container" [ngClass]="sizeBorderClass">
          <div class="size-field">
            <mat-form-field appearance="fill" style="width: 90px">
              <mat-label>D1</mat-label>
              <input
                type="number"
                matInput
                [formControl]="stoneSizeD1Control"
                [matAutocomplete]="stoneSizeD1"
                (blur)="checkSize()"
                min="0"
                max="999.99"
              />
              <mat-autocomplete
                #stoneSizeD1="matAutocomplete"
                [displayWith]="displayStoneSizeD1"
              >
                <mat-option
                  *ngFor="let size of getFilteredSizesD1"
                  [value]="size"
                >
                  {{ size.dimension_1 }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span class="size-unit">mm</span>
          </div>
          <div class="size-separator">X</div>
          <div class="size-field">
            <mat-form-field appearance="fill" style="width: 90px">
              <mat-label>D2</mat-label>
              <input
                type="number"
                matInput
                [formControl]="stoneSizeD2Control"
                [matAutocomplete]="stoneSizeD2"
                (blur)="checkSize()"
                min="0"
                max="999.99"
              />
              <mat-autocomplete
                #stoneSizeD2="matAutocomplete"
                [displayWith]="displayStoneSizeD2"
              >
                <mat-option
                  *ngFor="let size of getFilteredSizesD2"
                  [value]="size"
                >
                  {{ size.dimension_2 }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span class="size-unit">mm</span>
          </div>
          <div class="size-separator">X</div>
          <div class="size-field">
            <mat-form-field appearance="fill" style="width: 90px">
              <mat-label>D3</mat-label>
              <input
                type="number"
                matInput
                [formControl]="stoneSizeD3Control"
                [matAutocomplete]="stoneSizeD3"
                (blur)="checkSize()"
                min="0"
                max="999.99"
              />
              <mat-autocomplete
                #stoneSizeD3="matAutocomplete"
                [displayWith]="displayStoneSizeD3"
              >
                <mat-option
                  *ngFor="let size of getFilteredSizesD3"
                  [value]="size"
                >
                  {{ size.dimension_3 }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span class="size-unit">mm</span>
          </div>
          <div class="error-message" *ngIf="sizeBorderClass === 'is-invalid'">
            Invalid size.
            <span *ngIf="!isSizeOrderValid"
              >Size order should be descending.</span
            >
          </div>
        </div>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Carat</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="gem.caratsPerUnit"
            max="999.99"
          />
        </mat-form-field> -->
        <div>
          <mat-slide-toggle [(ngModel)]="gem.labGrown"
            >Lab Grown</mat-slide-toggle
          >
        </div>
        <div style="margin-top: 16px">
          <mat-slide-toggle [(ngModel)]="gem.traceable"
            >Traceable</mat-slide-toggle
          >
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        (click)="onSaveStone()"
        [disabled]="isSaving || !isFormValid"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>

<swal
  #confirmationDuplicateStone
  title="Duplicate Stone"
  text="Stone already exists. You can't add duplicate stones."
  icon="error"
  confirmButtonText="Close"
  [showConfirmButton]="true"
>
</swal>
